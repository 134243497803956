<template>
  <main>
    <div :class="{ container: $screen.breakpoint != 'sm' }" class="container">
      <div
        class="col-md-8 col-sm-12 py-5"
        :class="{ 'mx-auto': $screen.breakpoint != 'sm' }"
      >
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <PTabMenu :model="items" />
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      currentStep: 0,
      cv: {
        langues: [],
        qualifications: [],
        paysNaissance: {},
        emplois: [],
      },
      error: false,
      loading: false,
      items: [
        {
          label: "Signature numérique ",
          icon: "pi pi-fw pi-pencil",
          to: "/signature-numerique",
        },
        {
          label: "Importer une signature",
          icon: "pi pi-fw pi-upload",
          to: "/signature-import",
        },
      ],
    };
  },
  created() {
    // this.cv = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.cv
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    this.loading = true;
    this.fetchMonDossier()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },
  watch: {
    demandeur() {
      console.log(this.demandeur);
      this.cv = {
        ...this.demandeur,
      };
    },
  },
  computed: {
    ...mapGetters({
      demandeur: "demandeur/demandeur",
    }),
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
    estPeuOuPasInstruit() {
      return (
        this.cv.estInstruit != null &&
        (!this.cv.estInstruit ||
          (this.cv.estInstruit &&
            this.cv.niveauInstruction != null &&
            this.cv.niveauInstruction <= 10))
      );
    },
  },
  methods: {
    ...mapActions({
      updateDemandeurCv: "demandeur/updateDemandeurCv",
      fetchMonDossier: "demandeur/fetchMonDossier",
    }),
    // ...mapMutations({
    //   updateCv: "entreprise/addEntreprise",
    // }),
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    async submitForm() {
      if (await this.validateStep(this.currentStep)) {
        this.updateDemandeurCv(this.cv)
          .then(async (de) => {
            // const res = await this.$dialog.confirm({ text: `Vous-vous immédiatement procéder au remplissage de la fiche Azoli de ${de.nom} ${de.prenom}?` })
            this.$toast.success("Opération réussie!", {
              position: "bottom-right",
              duration: 5000,
            });
          })
          .catch((error) => {
            // this.error = true;
            this.$toast.error(
              `Le numéro de téléphone '${error}' existe déjà. Essayez avec un autre numéro de téléphone.`,
              {
                position: "bottom-right",
                duration: 35000,
              }
            );
          });
      }
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      console.log(this.convention);
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },
    async validateStep(step) {
      this.updateDemandeurCv(this.cv);
      console.log(step);
      console.log(this.cv);
      console.log(this.$refs[`currentTab_${step}`][0]);
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
      // return this.$refs[`currentTab_${step}`][0].do()
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
